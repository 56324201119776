import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useApiUrl from '../hooks/useApiUrl';

const axios = require('axios').default;

// ----------------------------------------------------------------------

const initialState = {
  patientes: []
};

const ManounaContext = createContext(initialState);

ManounaProvider.propTypes = {
  children: PropTypes.node
};

function ManounaProvider({ children }) {
  const { logout } = useAuth();
  const { manounaApiUrl } = useApiUrl();
  const [patienteFilter, setPatienteFilter] = useState('');

  const reloadPatientes = () =>
    new Promise((resolver) => {
      axios
        .get(`${manounaApiUrl}Patientes`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }
          if (err.response && err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          resolver([]);
        });
    });

  const getPatiente = (id) =>
    new Promise((resolver) => {
      axios
        .get(`${manounaApiUrl}Patiente/${id}`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }

          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          if (err.response.status === 404) {
            alert('Aucune patiente trouvé avec cet id. Réessayez plus tard');
          }

          resolver(null);
        });
    });

  const savePatiente = (id, patiente) =>
    new Promise((resolver) => {
      let request = null;
      if (id === '0') {
        request = axios.post(`${manounaApiUrl}patiente`, patiente);
      } else {
        request = axios.put(`${manounaApiUrl}patiente/${id}`, patiente);
      }

      request
        .then((resp) => {
          resolver(resp.data);
        })
        .catch((err) => {
          console.log('MANOUNA CONTEXT ERR', err.response.status);
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }
          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          resolver(null);
        });
    });

  const deletePatiente = (id) =>
    new Promise((resolver) => {
      axios
        .delete(`${manounaApiUrl}patiente/${id}`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }

          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          resolver(null);
        });
    });

  const saveVisit = (id, visit) =>
    new Promise((resolver) => {
      let request = null;
      if (id === '0') {
        request = axios.post(`${manounaApiUrl}visit`, visit);
      } else {
        request = axios.put(`${manounaApiUrl}visit/${id}`, visit);
      }

      request
        .then((resp) => resolver(resp.data))
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }
          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          resolver(null);
        });
    });

  const getVisit = (id) =>
    new Promise((resolver) => {
      axios
        .get(`${manounaApiUrl}Visit/${id}`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }

          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          if (err.response.status === 404) {
            alert('Aucune patiente trouvé avec cet id. Réessayez plus tard');
          }

          resolver(null);
        });
    });

  const deleteVisit = (id) =>
    new Promise((resolver) => {
      axios
        .delete(`${manounaApiUrl}visit/${id}`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }

          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          resolver(null);
        });
    });

  const addNewUser = (name, email) =>
    new Promise((resolver) => {
      axios
        .post(`${manounaApiUrl}newuser/${name}/${email}`)
        .then(() => resolver(true))
        .catch((err) => {
          console.log('error', JSON.stringify(err));
          resolver(false);
        });
    });

  const getViewPerMutuelle = (date) =>
    new Promise((resolver) => {
      axios
        .get(
          `${manounaApiUrl}ViewPerMutuelle/${
            date.getMonth() + 1
          }/${date.getFullYear()}`
        )
        .then((response) => resolver(response.data))
        .catch((err) => {
          console.log('error', JSON.stringify(err));
          resolver(false);
        });
    });

  const getTotalPerMonth = (date) =>
    new Promise((resolver) => {
      axios
        .get(
          `${manounaApiUrl}TotalPerMonth/${
            date.getMonth() + 1
          }/${date.getFullYear()}`,
          { date }
        )
        .then((response) => resolver(response.data))
        .catch((err) => {
          console.log('error', JSON.stringify(err));
          resolver(false);
        });
    });

  const getMyInfo = () =>
    new Promise((resolver) => {
      axios
        .get(`${manounaApiUrl}MyInfo`)
        .then((response) => {
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alert('Vous devez être connectée');
            logout();
          }

          if (err.response.status === 400) {
            alert("Une erreur s'est produite. Réessayez plus tard");
          }

          if (err.response.status === 404) {
            alert('Aucune info trouvée avec cet id. Réessayez plus tard');
          }

          resolver(null);
        });
    });

  const updateMyInfo = (myInfo) =>
    new Promise((resolver) => {
      axios
        .post(`${manounaApiUrl}UpdateMyInfo`, { ...myInfo })
        .then((response) => resolver(response.data))
        .catch((err) => {
          console.log('error', JSON.stringify(err));
          resolver(false);
        });
    });

  const getPdf = (mutuelle, date) =>
    new Promise((resolver) => {
      axios
        .get(
          `${manounaApiUrl}PDF/${mutuelle}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`
        )
        .then((response) => resolver(response.data))
        .catch((err) => {
          console.log('error', JSON.stringify(err));
          resolver(false);
        });
    });

  return (
    <ManounaContext.Provider
      value={{
        ...initialState,
        getPatiente,
        getVisit,
        savePatiente,
        deletePatiente,
        saveVisit,
        deleteVisit,
        reloadPatientes,
        patienteFilter,
        setPatienteFilter,
        addNewUser,
        // user info
        getMyInfo,
        updateMyInfo,
        // Stats
        getViewPerMutuelle,
        getTotalPerMonth,
        getPdf
      }}
    >
      {children}
    </ManounaContext.Provider>
  );
}

export { ManounaProvider, ManounaContext };
