import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
// hooks
import useOptions from '../hooks/useOptions';
import useApiUrl from '../hooks/useApiUrl';

const axios = require('axios').default;

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  method: 'auth0',
  login: () => {},
  logout: () => {}
};

const AuthContext = createContext(initialState);

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const { manounaApiUrl, appApiUrl } = useApiUrl();
  const [userInfo, setUserInfo] = useState(initialState);
  const [options, setOptions] = useOptions();

  useEffect(() => {
    const initialize = async () => {
      try {
        const sessionToken = localStorage.getItem('sessionToken');

        if (!sessionToken) {
          setUserInfo({
            ...userInfo,
            isInitialized: true,
            isAuthenticated: false
          });
          return;
        }

        axios.defaults.headers.common.Authorization = sessionToken;

        // If the user has not a valid token
        // An error is thrown and catched below
        const info = await axios.get(`${manounaApiUrl}checkSession`);
        const { name, email, mutuelles, tarifs, profileComplete } = info.data;

        setOptions({
          ...options,
          mutuelles,
          tarifs
        });

        setUserInfo({
          ...userInfo,
          name,
          email,
          profileComplete,
          isInitialized: true,
          isAuthenticated: true
        });
      } catch (err) {
        localStorage.removeItem('sessionToken');
        delete axios.defaults.headers.common.Authorization;

        setUserInfo({
          ...userInfo,
          isInitialized: true,
          isAuthenticated: false
        });
      }
    };

    initialize();
  }, []);

  const authLogin = (username, password) =>
    new Promise((resolver) => {
      axios
        .post(`${appApiUrl}login`, { username, password })
        .then((response) => {
          const {
            name,
            email,
            sessionToken,
            mutuelles,
            tarifs,
            profileComplete
          } = response.data;

          setOptions({
            ...options,
            mutuelles,
            tarifs
          });

          axios.defaults.headers.common.Authorization = sessionToken;
          localStorage.setItem('sessionToken', sessionToken);
          setUserInfo({
            ...userInfo,
            name,
            email,
            profileComplete,
            isAuthenticated: true
          });
          resolver(response.data);
        })
        .catch(() => {
          resolver(null);
        });
    });

  const logout = () => {
    localStorage.removeItem('sessionToken');
    delete axios.defaults.headers.common.Authorization;
    setUserInfo({ ...userInfo, isAuthenticated: false });
  };

  const requestResetPassword = (email) =>
    new Promise((resolver) => {
      axios
        .get(`${appApiUrl}RequestResetPassword/${email}`)
        .then(() => {
          resolver(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert('Aucun utilisateur trouvé correspondant à cet mail');
          }

          resolver(false);
        });
    });

  const resetPassword = (token, newPassword) =>
    new Promise((resolver) => {
      axios
        .get(`${appApiUrl}resetpassword/${token}/${newPassword}`)
        .then((response) => {
          const {
            name,
            email,
            sessionToken,
            mutuelles,
            tarifs,
            profileComplete
          } = response.data;

          setOptions({
            ...options,
            mutuelles,
            tarifs
          });

          axios.defaults.headers.common.Authorization = sessionToken;
          localStorage.setItem('sessionToken', sessionToken);
          setUserInfo({
            ...userInfo,
            name,
            email,
            profileComplete,
            isAuthenticated: true
          });
          resolver(response.data);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert('Token invalide');
          }

          resolver(false);
        });
    });

  return (
    <AuthContext.Provider
      value={{
        ...userInfo,
        setIsProfileComplete: (newValue) =>
          setUserInfo({
            ...userInfo,
            profileComplete: newValue
          }),
        login: authLogin,
        requestResetPassword,
        resetPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
