import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/pdf',
      element: (
        <AuthGuard>
          <Pdf />
        </AuthGuard>
      )
    },
    {
      path: '/profile',
      element: (
        <AuthGuard>
          <Profile />
        </AuthGuard>
      )
    },
    {
      path: '/stats',
      element: (
        <AuthGuard>
          <Stats />
        </AuthGuard>
      )
    },
    {
      path: '/per-mutuelle',
      element: (
        <AuthGuard>
          <PerMutuellePage />
        </AuthGuard>
      )
    },
    {
      path: '/test',
      element: (
        <AuthGuard>
          <TestPage />
        </AuthGuard>
      )
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <AdminPage />
        </AuthGuard>
      )
    },
    {
      path: '/reset-password/:token',
      element: <ResetPassword />
    },
    {
      path: '/request-reset-password',
      element: <RequestResetPassword />
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <AuthGuard>
              <LandingPage />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: '/patiente/:id',
      element: (
        <AuthGuard>
          <PatientePage />
        </AuthGuard>
      )
    },
    {
      path: '/visit/:patienteId/:id',
      element: (
        <AuthGuard>
          <VisitPage />
        </AuthGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const PatientePage = Loadable(lazy(() => import('../pages/PatientePage')));
const VisitPage = Loadable(lazy(() => import('../pages/VisitPage')));
const AdminPage = Loadable(lazy(() => import('../pages/Admin')));
const TestPage = Loadable(lazy(() => import('../pages/TestPage')));
const Stats = Loadable(lazy(() => import('../pages/Stats')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/authentication/ResetPassword'))
);
const RequestResetPassword = Loadable(
  lazy(() => import('../pages/authentication/RequestResetPassword'))
);
const PerMutuellePage = Loadable(
  lazy(() => import('../pages/PerMutuellePage'))
);
const Pdf = Loadable(
  lazy(() => import('../pages/Profile'))
);
