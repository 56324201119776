import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, AppBar, Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
// icons
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
// contexts
import { ManounaContext } from '../../contexts/ManounaContext';
import DashboardSidebar from '../dashboard/DashboardSidebar';
// hooks

// ----------------------------------------------------------------------

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2)
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

// ----------------------------------------------------------------------

HideOnScroll.propTypes = {
  children: PropTypes.object
};

ShowOnScroll.propTypes = {
  children: PropTypes.object
};

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return (
    <Collapse in={!trigger} unmountOnExit>
      {children}
    </Collapse>
  );
}

function ShowOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return (
    <Fade in={trigger} unmountOnExit>
      {children}
    </Fade>
  );
}

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const [open, setOpen] = React.useState(false);
  const { patienteFilter, setPatienteFilter } = useContext(ManounaContext);
  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line
    setPatienteFilter('');

    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: '230px'
      }}
    >
      <AppBar position="fixed" sx={{ pt: `env(safe-area-inset-top)` }}>
        <StyledToolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <DashboardSidebar
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
          />
          <ShowOnScroll>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, alignSelf: 'center' }}
            >
              Patientes
            </Typography>
          </ShowOnScroll>
          <Box sx={{ flexGrow: 1 }} />

          <IconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={() => navigate('patiente/0')}
          >
            <AddIcon />
          </IconButton>
        </StyledToolbar>
        <HideOnScroll>
          <Box sx={{ mx: 3, mb: 2 }}>
            <Typography
              variant="h1"
              noWrap
              component="div"
              sx={{ flexGrow: 1, alignSelf: 'flex-end' }}
            >
              Patientes
            </Typography>
          </Box>
        </HideOnScroll>
        <Box sx={{ ml: 3, mr: 3, mb: 3 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Recherche"
              inputProps={{ 'aria-label': 'search' }}
              fullWidth
              value={patienteFilter}
              onChange={(e) => setPatienteFilter(e.target.value)}
              endAdornment={
                patienteFilter ? (
                  <IconButton
                    size="large"
                    aria-label="display more actions"
                    edge="end"
                    color="inherit"
                    sx={{ mr: 0.5 }}
                    onClick={() => setPatienteFilter('')}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : null
              }
            />
          </Search>
        </Box>
      </AppBar>
    </Box>
  );
}
