// routes
// components
import DescriptionIcon from '@mui/icons-material/Description';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  shield: getIcon('shield')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Accueil',
        path: '/',
        icon: ICONS.user
      },
      {
        title: 'Visite par mutuelle',
        path: '/per-mutuelle',
        icon: <DescriptionIcon />
      },
      {
        title: 'Statistique',
        path: '/stats',
        icon: ICONS.dashboard,
        isForAdmin: true
      },
      {
        title: 'Admin',
        path: '/admin',
        icon: ICONS.dashboard,
        isForAdmin: true
      },
      {
        title: 'Test',
        path: '/test',
        icon: ICONS.shield,
        isForAdmin: true
      }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.app.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.app.pageFour },
  //         { title: 'Five', path: PATH_DASHBOARD.app.pageFive },
  //         { title: 'Six', path: PATH_DASHBOARD.app.pageSix }
  //       ]
  //     }
  //   ]
  // }
];

export default sidebarConfig;
