import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
// hooks

// ----------------------------------------------------------------------

const initialState = {
  mutuelles: [],
  tarifs: []
};

const OptionsContext = createContext(initialState);

OptionsProvider.propTypes = {
  children: PropTypes.node
};

function OptionsProvider({ children }) {
  const [options, setOptions] = useState(initialState);

  return (
    <OptionsContext.Provider
      value={{
        options,
        setOptions
      }}
    >
      {children}
    </OptionsContext.Provider>
  );
}

export { OptionsProvider, OptionsContext };
